@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bubble {
  position: relative;
  max-width: calc(var(--app-container-width) - 80px);
  min-width: calc(var(--app-container-width) * 0.35);
  border: none;
  border-radius: 6px;
  padding: 3px 8px 15px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  animation: fadeIn .3s ease-in-out;
}

.user, .assistant {
  &::before {
    position: absolute;
    top: 0;
  }
}

.time {
  position: absolute;
  bottom: 0;
  right: 6px;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.user + .assistant,
.assistant + .user {
  margin-top: 12px;
}

.user + .user,
.assistant + .assistant {
  margin-top: 8px;
}

.user {
  align-self: flex-end;
  border-top-right-radius: 0;
  background-color: var(--primary-color);
  color: var(--white-color);
  
  .time {
    color: #EBEFF5;
  }

  &::before {
    content: url('../../public/bubble-corner-user.svg');
    right: -9px;
  }
}

.assistant {
  align-self: flex-start;
  border-top-left-radius: 0;
  background-color: var(--assistant-bubble-color);
  color: var(--text-color);

  .time {
    color: #9BA7B7;
  }
  
  &::before {
    content: url('../../public/bubble-corner-assistant.svg');
    left: -9px;
  }
}

.isError {
  color: #FF524F;
  border: 1px solid #FF9B99;
  border-radius: 6px;

  &::before {
    content: '';
  }
}
