@import './mixins/breakpoints';

.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  max-height: var(--app-container-height);
  background-color: #fff;
  border-radius: 12px;
  filter: drop-shadow(0px 6px 32px rgba(24, 33, 50, 0.08));
  
  @include sm {
    min-height: var(--app-container-height);
    width: var(--app-container-width);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: calc(var(--app-header-height) - 16px);
  padding: 8px 16px;
  border-bottom: 1px solid #EBEFF5;
  box-sizing: content-box;
}

.messages {
  flex-grow: 1;
  height: 100%;
  padding: 14px 16px;
  overflow-y: auto;
  background-color: #fff;
}

.input {
  border-top: 1px solid #EBEFF5;
}