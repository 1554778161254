.form {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.input {
  width: 100%;
  max-height: 75px;
  padding: 14px 40px 14px 24px;
  border: none;
  border-radius: 12px;
  color: var(--text-color);
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  resize: none;
  
  &::placeholder {
    color: #657289;
  }
  
  &:focus, &:focus-visible, &:active {
    border: none;
    outline: none;
  }
}


.sendBtn {
  position: absolute;
  right: 13px;
  bottom: 0;
  padding: 17px 0;
  border: none;
  background-color: transparent;
  font-size: 17px;
  cursor: pointer;

  & svg {
    fill: #657289;
  }

  &.sent {
    animation: flyAway .45s ease-in-out;
  }
}

@keyframes flyAway {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}