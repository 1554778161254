@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

:root {
  --app-container-width: 360px;
  --app-container-height: 580px;
  --app-header-height: 48px;
  --app-input-height: 48px;

  --primary-color: #2881EA;
  --assistant-bubble-color: #F7F8FA;
  --text-color: #182132;
  --white-color: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* add dark theme overrides */
  }
}

