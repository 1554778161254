.avatar {
  display: flex;
  align-items: center;
  
  img {
    border-radius: 50%;
    background: #C1DDFE;
    margin: 0;
    margin-right: 14px;
    border: 1px solid #E2EFFF;
  }
  
  span {
    color: #2C2C2E;
  }
}

.icons {
  position: relative;
  display: flex;
  align-items: center;

  .iconButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.icon {
  & path {
    stroke: #9BA7B7;
    fill: #9BA7B7;
  }

  &:hover, &.active {
    path {
      stroke: #2C2C2E;
      fill: #2C2C2E;
    }
  }
}
