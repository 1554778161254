.container {
  position: absolute;
  top: 35px;
  right: 15px;
  border-radius: 6px;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 6px 32px rgba(24, 33, 50, 0.08);
  z-index: 10;
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.listItem {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #44526C;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.optionButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px 8px;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;

  span {
    margin-right: 16px;
  }

  &:hover, &:active, &.active {
    background: #EEF6FF;
  }

}

.languagePopup {
  top: -8px;
  right: unset;
  translate: calc(-100% - 16px);
}

.languageItem {
  min-width: 120px;
  
  svg {
    stroke: #2881EA;
  }
}