.messageList {
  display: flex;
  flex-direction: column;
  
  .date {
    &:first-of-type {
      margin-top: 0;
    }
    
    margin: 14px 0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #9BA7B7;
  }
}
