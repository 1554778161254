@import './mixins/breakpoints';

.appContainer {
  position: fixed;
  bottom: 0.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  font-family: 'Poppins', sans-serif;
  
  z-index: 100000;
  
  @include sm {
    bottom: 2.5rem;
    right: 2.5rem;
  }
}

.activationButton {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  background: var(--primary-color);
  margin-top: 20px;

  cursor: pointer;
  
  svg {
    width: 20px;
    height: 20px;
    stroke: white;
    
    @include sm {
      width: 25px;
      height: 25px;
    }
  }
}
